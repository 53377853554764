@import-normalize;

@font-face {
  font-family: "FocoBold";
  src: url("./Foco/Foco_W_Bd.woff2") format("woff2");
  font-weight: bold;
}

@font-face {
  font-family: "FocoRegular";
  src: url("./Foco/Foco_W_Rg.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "FocoLight";
  src: url("./Foco/Foco_W_Lt.woff2") format("woff2");
  font-weight: 300;
}

@font-face {
  font-family: "CalibriBold";
  src: url("./Calibri/Calibri-Bold.woff2") format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: "CalibriRegular";
  src: url("./Calibri/Calibri.woff2") format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: "CalibriLight";
  src: url("./Calibri/Calibri-Light.woff2") format("woff2");
  font-weight: normal;
}